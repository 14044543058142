<template>
  <div class="grid-container">
    <div class="grid-x grid-padding-x pt-5">
      <div class="cell small-12">
        <h1>Add the main Viewing Room image</h1>
        <p>This image is used for the list of viewing rooms and the title image of the room.</p>
      </div>
    </div>
    <div class="grid-x grid-padding-x pt-5">
      <div class="cell small-12 medium-2">
        <select v-model="imagePosition">
          <option value="0">Image on the left</option>
          <option value="1">Image on the right</option>
        </select>
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12">
        <app-uploader @uploadComplete="uploaded = true" :room="this.$route.params.id" />
      </div>
    </div>
    <div v-if="uploaded === true" class="grid-x grid-padding-x pt-5">
      <div class="cell small-12">
        <button @click="saveMainImage" class="button">Next</button>
      </div>
    </div>
  </div>
</template>

<script>
import Uploader from '@/components/Uploader.vue';
import axios from '../axios';

export default {
  name: 'AddMainImage',
  components: {
    appUploader: Uploader,
  },
  data() {
    return {
      imagePosition: 0,
      uploaded: false,
    };
  },
  methods: {
    saveMainImage() {
      axios.get(`/ViewingRooms/savePosition/${this.$route.params.id}/${this.imagePosition}.json?token=${this.$store.state.token}`)
        .then(() => {
          this.$router.push(`/edit-room/${this.$route.params.id}`);
        });
    },
  },
};
</script>
